var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Moje konto ")]},proxy:true},{key:"content",fn:function(){return [(!_vm.orders.length && !_vm.isLoadingOrders)?_c('p',[_vm._v(" Brak zamówień ")]):_vm._e(),(_vm.orders.length)?_c('table',{staticClass:"my-account__table"},[_c('thead',[_c('tr',{staticClass:"my-account__tr"},[_c('th',{staticClass:"my-account__th"},[_vm._v(" ID ")]),_c('th',{staticClass:"my-account__th"},[_vm._v(" Data ")]),_c('th',{staticClass:"my-account__th"},[_vm._v(" Status zamówienia ")]),_c('th',{staticClass:"my-account__th"},[_vm._v(" Status płatności ")]),_c('th',{staticClass:"my-account__th"},[_vm._v(" Kwota Brutto ")]),_c('th',{staticClass:"my-account__th"})])]),_c('tbody',_vm._l((_vm.orders),function(ref){
          var id = ref.id;
          var createdAt = ref.createdAt;
          var status = ref.status;
          var payment = ref.payment;
          var price = ref.price;
          var priceFormatted = ref.priceFormatted;
return _c('tr',{key:id,staticClass:"my-account__tr"},[_c('td',{staticClass:"my-account__td"},[_c('router-link',{staticClass:"my-account__url",attrs:{"to":{ name: 'order', params: { orderId: id } }}},[_vm._v(" "+_vm._s(id)+" ")])],1),_c('td',{staticClass:"my-account__td"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(createdAt))+" ")]),_c('td',{staticClass:"my-account__td"},[_vm._v(_vm._s(_vm.getStatus(status)))]),_c('td',{staticClass:"my-account__td"},[_vm._v(_vm._s(_vm.getPayment(payment)))]),_c('td',{staticClass:"my-account__td"},[_vm._v(_vm._s(priceFormatted))]),_c('td',{staticClass:"my-account__td my-account__td--download"},[(payment === 'paid')?_c('a',{staticClass:"button button--full",attrs:{"href":_vm.getDownloadUrl(id)}},[_vm._v(" Pobierz ")]):(status === 'incomplete' && payment === 'unpaid')?_c('router-link',{staticClass:"button button--full",attrs:{"to":{
                name: 'checkout-payment',
                query: {
                  id: id,
                  amount: price,
                },
              }}},[_vm._v(" Opłać ")]):[_vm._v(" — ")]],2)])}),0)]):_vm._e()]},proxy:true},{key:"log-out",fn:function(){return [_c('button',{staticClass:"button",class:{
        'button--loading': _vm.isSigningOut,
      },on:{"click":_vm.onSignOutClick}},[_vm._v(" Wyloguj ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }