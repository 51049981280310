<template>
  <component :is="component">
    <template #header>
      Moje konto
    </template>
    <template #content>
      <p v-if="!orders.length && !isLoadingOrders">
        <!-- TODO: CSS -->
        Brak zamówień
      </p>
      <table v-if="orders.length" class="my-account__table">
        <thead>
          <tr class="my-account__tr">
            <th class="my-account__th">
              ID
            </th>
            <th class="my-account__th">
              Data
            </th>
            <th class="my-account__th">
              Status zamówienia
            </th>
            <th class="my-account__th">
              Status płatności
            </th>
            <th class="my-account__th">
              Kwota Brutto
            </th>
            <th class="my-account__th"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{
              id,
              createdAt,
              status,
              payment,
              price,
              priceFormatted,
            } in orders"
            :key="id"
            class="my-account__tr"
          >
            <td class="my-account__td">
              <router-link
                :to="{ name: 'order', params: { orderId: id } }"
                class="my-account__url"
              >
                {{ id }}
              </router-link>
            </td>
            <td class="my-account__td">
              {{ createdAt | formatDate }}
            </td>
            <td class="my-account__td">{{ getStatus(status) }}</td>
            <td class="my-account__td">{{ getPayment(payment) }}</td>
            <td class="my-account__td">{{ priceFormatted }}</td>
            <td class="my-account__td my-account__td--download">
              <a
                v-if="payment === 'paid'"
                :href="getDownloadUrl(id)"
                class="button button--full"
              >
                Pobierz
              </a>
              <router-link
                v-else-if="status === 'incomplete' && payment === 'unpaid'"
                class="button button--full"
                :to="{
                  name: 'checkout-payment',
                  query: {
                    id,
                    amount: price,
                  },
                }"
              >
                Opłać
              </router-link>
              <template v-else>
                &mdash;
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template #log-out>
      <button
        class="button"
        :class="{
          'button--loading': isSigningOut,
        }"
        @click="onSignOutClick"
      >
        Wyloguj
      </button>
    </template>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import MyAccountTemplate from '@/vue/components/account/my-account-template.vue';
import MyAccountLoader from '@/vue/components/account/my-account-loader.vue';
import getProductDownloadUrl from '@/vue/utils/getProductDownloadUrl';

export default {
  name: 'MyAccount',

  components: { MyAccountTemplate, MyAccountLoader },

  filters: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
  },

  data: () => ({
    isSigningOut: false,
    isInitialized: false,
  }),

  computed: {
    ...mapGetters('user', {
      isLoadingOrders: 'isLoadingOrders',
      orders: 'orders',
    }),

    component() {
      return this.isLoadingOrders || !this.isInitialized
        ? MyAccountLoader
        : MyAccountTemplate;
    },
  },

  async created() {
    await this.getOrders();
    this.isInitialized = true;
  },

  methods: {
    ...mapActions('user', {
      signOut: 'signOut',
      getOrders: 'getOrders',
    }),

    getStatus(status) {
      return {
        complete: 'Ukończone',
        processing: 'Przetwarzanie płatności',
        incomplete: 'W trakcie',
      }[status];
    },

    getPayment(payment) {
      return {
        unpaid: 'Nie opłacone',
        authorized: 'Płatność w toku',
        paid: 'Opłacone',
        refunded: 'Zwrot',
      }[payment];
    },

    getDownloadUrl(orderId) {
      return getProductDownloadUrl(orderId);
    },

    async onSignOutClick() {
      try {
        this.isSigningOut = true;
        await this.signOut();
        this.$router.push({
          name: 'home',
        });
      } catch (e) {
        this.$bugsnag.notify('Unable to sign out', e);
      } finally {
        this.isSigningOut = false;
      }
    },
  },
};
</script>
