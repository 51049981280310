<template>
  <MyAccountTemplate #content>
    <div v-for="n in 10" :key="n" class="rect">
      <Rectangle :height="3" />
    </div>
  </MyAccountTemplate>
</template>

<script>
import MyAccountTemplate from '@/vue/components/account/my-account-template.vue';
import Rectangle from '@/vue/components/skeleton/rectangle.vue';

export default {
  name: 'MyAccountLoader',
  components: {
    Rectangle,
    MyAccountTemplate,
  },
};
</script>

<style lang="scss">
.rect {
  margin-bottom: 4px;
}
</style>
